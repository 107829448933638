/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Regions.unshift(item)
  },
  SET_Region(state, Regions) {
    debugger;
    try {
      //const parsedData = JSON.parse(countries);
      state.Regions = Regions;
      //state.OutbreakCountries =  countries;
    } catch (error) {
      console.error("Failed to parse Regions data JSON:", error);
      state.Regions = [];
    }
  },
  UPDATE_Country(state, Region) {
    debugger
    const RegionIndex = state.Regions.findIndex((p) => p.ID == Region.Id)
    Object.assign(state.Regions[RegionIndex], Region)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.Regions.findIndex((p) => p.ID == itemId)
      state.Regions.splice(ItemIndex, 1)
  },
}
